import App from 'next/app';
import NextNProgress from 'nextjs-progressbar';
import Script from 'next/script';

import { AppStateProvider } from '../hooks/context';
import { fetchMenu } from '../services/menu';
import { fetchLanguages, fetchParameters } from '../services/settings';

import 'react-toastify/dist/ReactToastify.min.css';
import '../assets/styles/main.scss';

function MyApp({ Component, pageProps, data }) {
  return (
    <AppStateProvider initialContext={data}>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {process.env.NODE_ENV === 'production' &&
          `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MLK2WZQ3');`}
      </Script>
      <NextNProgress color="#ffc738" height={4} stopDelayMs={50} options={{ showSpinner: false }} />
      <Component {...pageProps} />
    </AppStateProvider>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { locale } = appContext.router;

  const isMobile = (
    appContext.ctx.req ? appContext.ctx.req.headers[ 'user-agent' ] : navigator.userAgent
  ).match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i);

  const [menu, languages, parameters] = await Promise.all([
    fetchMenu({ locale }),
    fetchLanguages({ locale }),
    fetchParameters({ locale }),
  ]);

  return {
    ...appProps,
    data: {
      menu,
      languages,
      parameters,
      isMobile: Boolean(isMobile),
    },
  };
};

export default MyApp;
