import axios from 'axios';
// import { setupCache } from 'axios-cache-adapter';

// eslint-disable-next-line
// const cache = setupCache({
//   maxAge: 5 * 60 * 1000,
//   exclude: {
//     methods: ['post', 'patch', 'put', 'delete'],
//   },
// });

const _locales = {
  ka: 'ge',
  en: 'en',
};

const configureAxios = ({ locale }) => {
  const instance = axios.create({
    baseURL: process.env.BASE_URL,
    // adapter: cache.adapter,
  });

  instance.interceptors.request.use((request) => {
    // eslint-disable-next-line
    request.headers['Content-Language'] = _locales[locale];
    return request;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log(`
        ---------------------------------------------------------------------

        - URL: ${error.config?.url}
        - PARAMS: ${JSON.stringify(error.config?.params)}
        - CODE ${error.response?.status}
        - MESSAGE: ${error?.response?.data?.message}

        ---------------------------------------------------------------------
      `);

      return Promise.reject(error);
    },
  );

  return instance;
};

export const useHttp = ({ locale = 'en' }) => {
  const _http = configureAxios({ locale });

  return {
    request: (_params) => _http(_params),
  };
};

export const http = configureAxios({ locale: 'en' });
