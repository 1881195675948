/* eslint-disable react-hooks/rules-of-hooks */
import { useHttp } from '../hooks/network';

export const fetchMenu = async ({ locale, params }) => {
  const { request } = useHttp({ locale });

  const response = await request({
    url: '/menu',
    params,
  });

  return response.data;
};
