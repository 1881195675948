/* eslint-disable react-hooks/rules-of-hooks */
import { useHttp } from '../hooks/network';

export const fetchParameters = async ({ locale }) => {
  const { request } = useHttp({ locale });

  const response = await request({
    url: '/settings',
  });

  return response.data;
};

export const fetchLanguages = async ({ locale }) => {
  const { request } = useHttp({ locale });

  const response = await request({
    url: '/translation',
  });

  return response.data;
};
