import { createContext, useContext, useState } from 'react';

const AppStateContext = createContext(undefined);

export function AppStateProvider({ children, initialContext = {} }) {
  const [context, setContext] = useState(initialContext);

  return (
    <AppStateContext.Provider
      value={{
        context,
        setContext,
      }}>
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppStateContext() {
  const context = useContext(AppStateContext);

  if (!context) {
    throw new Error('useAppStateContext must be used inside a `AppStateProvider`');
  }

  return context;
}
